import React from 'react'
import EighteenPlus from 'image/Rate18+.png'
import { useLocation } from 'react-router-dom'
import styles from './footer.module.sass'
import footerInfo from '../common/variables'

const styledRoot = pathname => ({
  backgroundColor: ['/', '/news', '/gameIntro', '/mascotIntro', '/propsIntro', '/giftIntro', '/vipIntro', '/activityIntro', '/deposit', '/passbook', '/modifyPassword', '/rank', '/question', '/suspenseList', '/rightsDescription', '/serviceDetail', '/probabilityDetail'].includes(pathname) ? '#131630cc' : 'transparent',
})

function Footer() {
  const { pathname } = useLocation()

  return (
    <div
      className={styles.root}
      style={styledRoot(pathname)}
    >
      <div className={styles.email}>
        {footerInfo.email}
      </div>
      <div className={styles.announcement}>
        {footerInfo.announcement}
      </div>
      <div className={styles.reCaptchaInfo}>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">
          {' '}
          Privacy Policy
          {' '}
        </a>
        and
        <a href="https://policies.google.com/terms">
          {' '}
          Terms of Service
          {' '}
        </a>
        apply.
      </div>
      <div className={styles.copyRight}>
        {footerInfo.copyRight}
      </div>
      <div className={styles.rating} title="限制級 18+">
        <img src={EighteenPlus} alt="限制級 18+" />
      </div>
    </div>
  )
}

export default Footer
